@import 'sass/shared';

.privacy-modal {
    .agree {
        margin: 20px 0;
    }
}

.privacy-title {
    @extend .roboto-bold, .h1;
    text-align: center;
    margin-bottom: 20px;
}
