@import 'sass/shared.scss';

.account-navigation {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    background: rgba(255, 255, 255, 1);
    z-index: 9;
    display: none;
    padding-top: 60px;
    @include breakpoint(tablet-portrait-up) {
        padding-top: 110px;
        right: 10%;
        width: 300px;
        left: inherit;
        bottom: inherit;
    }

    &.-active {
        display: block;
    }
}

.current-user-card {
    display: flex;
    flex: 1;
    background: $brand-yellow;
    color: $brand-gray;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 40px;
    flex-wrap: wrap;
    position: relative;
    // padding: 30px;

    > .menu-toggle-container {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    > .current-user-name {
        @extend .roboto-medium;
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        margin-block-end: 0;
        padding: 15px 15px 15px;
        text-align: center;
        color: $brand-white;
        overflow: hidden;
        width: 100%;
        word-wrap: break-word;
    }

    > .current-user-email {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        margin-block-end: 0;
        padding: 0 15px 15px;
    }
}

.menu-toggle-container {
    > .menu-close-toggle {
        padding: 10px;
    }
}

.menu-close-toggle {
    > .icon-plus {
        display: block;
        transform: rotate(45deg);
    }
}

.account-nav-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    > .account-nav-menu-item {
    }
}

.account-nav-menu-item {
    display: block;
    > .account-nav-menu-link {
    }
}

.account-nav-menu-link {
    @extend .p, .roboto-medium;

    display: block;
    text-align: center;
    padding: 10px;
    color: $brand-gray;
}

.logout-trigger-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.logout-trigger {
    @extend .roboto-medium;
    @extend .p;

    border: 0px;
    height: 36px;
    padding: 0 30px;
    border-radius: 5px;
    background: $brand-yellow;
    color: $brand-white;
}

.menu-close-toggle {
    border: none;
    background: transparent;
    .icon-plus {
        color: white;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 5;
    display: none;
    opacity: 0;
    &.-active {
        display: block;
        opacity: 1;
    }
}
