@import 'sass/shared.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.welcome-notice {
    z-index: 5;
    transform: scale(0.8);
    // margin-top: 50px;
    margin-top: 25px;
    margin-bottom: 10px;
  
    @include breakpoint(tablet-portrait-up) {
      transform: scale(1);
      margin-top: 20px;
    }
  
    .top {
      margin-bottom: 15px;
      text-align: center;
    }
  
    .bottom {
      text-align: center;
    }
  
    .note {
      @extend .tradesmith, .h2;
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
      background: radial-gradient(ellipse at center, rgba(244, 244, 244, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
      padding: 10px 20px;
      background: rgba(244, 244, 244, 1);
      text-align: center;
      display: inline-block;
  
      &.-welcome {
        margin-right: 30px;
        transform: rotate(-3deg);
      }
  
      &.-back {
        transform: rotate(5deg);
      }
  
      &.-name {
        text-shadow: 2px 2px 1px #6fb4cc;
      }
    }
}

#dynamic-banner-wrapper {
    .banner-main-container {
      position: relative;
      .slick-prev, .slick-next {
        width: 75px;
        height: 75px;
      }
      .slick-prev:before, .slick-next:before {
        font-size: 35px;
        color: #C52127;
      }
      .welcome-notice {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 25px;
        margin-bottom: 10px;
      }
      .call-to-action {
        position: absolute;
        z-index: 9;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2.25rem;
        .white-bg {
          font-size: 25px;
          height: 50px;
        }
      }
    }
  }


.image-container {
    .slick-list {
        .slick-track {
            .slick-slide {
                img {
                    margin: 0 auto;
                    width: 100%;
                    height: 495px;
                }
            }
        }
    }
}