@import 'sass/shared.scss';

.cart-indicator {
    padding: 0 5px;
    height: 33px;
    position: relative;
    display: flex;
    align-items: flex-end;

    @include breakpoint(tablet-landscape-up){
        height: 39px;
    }



    > .cart-items-counter {
        @extend .roboto-bold;
        @extend .smallest;
        background: transparent url('~Assets/images/cart_count_bg.png') no-repeat;
        background-size: contain;
        position: absolute;
        width: 23px;
        height: 23px;
        display: block;
        justify-content: center;
        align-items: center;
        color: $brand-white;
        position: absolute;
        top: -5px;
        left: -5px;
        text-overflow: ellipsis;
        padding: 6px 0 0;
        text-align: center;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .empty-icon {
        // margin-bottom: 5px;
    }
}
