@import 'sass/shared.scss';

.products-list-cont.-summary {
    margin-top: 0;

    @include breakpoint(tablet-landscape-up) {
        margin-top: 60px;
    }

    .cart-items-col {
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }

    .cart-summary-col {
        display: block;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }
}

.cart-groups {
    > .cart-group-items-container {
    }
}

.cart-group-items-container {
    .ant-collapse-item {
        background: transparent;
        border: 0px;
    }

    .ant-collapse-item .ant-collapse-header {
        // background: transparent;
        padding: 20px;
    }

    .ant-collapse-item-active {
        .icon-arrow-down {
            transform: rotate(0deg);
        }

        .case-summary-col .case-summary {
            display: none;
        }

        @include breakpoint(tablet-landscape-up) {
            .case-type-col .case-summary {
                display: none;
            }
        }
    }

    // &:last-child {
    //     .cart-group-items {
    //         border-bottom: 0;
    //     }
    // }
}

.cart-panel-heading {
    display: flex;
    justify-content: space-between;
    border-bottom: rgba($brand-lightgray, 0.5) 5px solid;
    padding-bottom: 5px;
    align-items: center;

    @include breakpoint(tablet-landscape-up) {
        align-items: flex-start;
        border-bottom: 0;
    }

    > .cart-info-col {
        @include breakpoint(tablet-landscape-up) {
            flex-basis: 100%;
            justify-content: space-between;
        }
    }

    > .panel-state-icon-col {
        @include breakpoint(tablet-landscape-up) {
            display: none;
        }
    }
}

.cart-info-col {
    @include breakpoint(tablet-landscape-up) {
        display: flex;
    }

    > .name-qty-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint(tablet-landscape-up) {
            flex: 1;
        }
    }

    > .summary-items-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    > .case-price-row {
        display: none;
        text-align: center;
        padding: 0 $standard-spacing;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }
}

.case-price-row {
    > .heading {
        @extend .h4;
        // @extend .roboto-italic;
        @extend .roboto;
        text-transform: uppercase;
        color: $brand-lightgray;
    }

    > .case-price {
        @extend .roboto-bold;
        @extend .p;
        color: $brand-lightgray;
        display: block;

        padding: 3px 7.5px;
        border: 1px solid $brand-lightgray;
    }
}

.name-qty-row {
    > .case-type-icon-col {
        @extend .h5;
        @extend .roboto-bold;
        color: $brand-lightgray;
        margin-right: 10px;
    }

    > .case-type-col {
        @include breakpoint(tablet-landscape-up) {
            max-width: 300px;
        }
    }

    > .panel-state-icon-col {
        padding-left: 10px;
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
        }
    }
}

.summary-items-row {
    > .case-items-col {
        margin-right: 10px;
    }

    > .case-summary-col {
        @include breakpoint(tablet-landscape-up) {
            display: none;
        }
    }
}

.missing-items-row {
    display: none;

    @include breakpoint(tablet-landscape-up) {
        display: flex;
        align-items: flex-end;
    }

    > .insufficient-message {
        @extend .roboto-italic;
        display: none;
        text-align: center;
        color: $brand-red;
        margin: 0;
        padding: 3px 5px;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }
}

.case-items-col {
    > .heading {
        @extend .h4;
        // @extend .roboto-italic;
        @extend .roboto;
        text-transform: uppercase;
        color: $brand-lightgray;

        display: none;
        text-align: center;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }

    > .case-items-box-action {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.case-items-box-action {
    > .delete-cart-item {
        @extend .h4;
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
        }
    }
}

.case-items-box {
    @extend .roboto-bold;
    @extend .p;
    color: $brand-lightgray;
    display: block;
    padding: 0 5px;
    border: 1px solid $brand-success;

    @include breakpoint(tablet-landscape-up) {
        padding: 3px 7.5px;
    }

    &.-incomplete {
        border-color: $brand-red;

        > .case-size {
            color: $brand-red;
        }
    }

    > .case-size {
        color: $brand-success;
    }

    > .case-size-max {
    }
}

.case-type-icon-col {
    > .icon-case {
    }
}

.case-type-col {
    > .case-type {
        @extend .h5;
        @extend .roboto-bold;
        color: $brand-lightgray;
        margin: 0;
    }

    > .case-summary {
        display: none;
        margin: 0;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }
}

.case-summary-col {
    @extend .roboto-italic;
    @extend .p;
    color: $brand-lightgray;

    > .case-summary {
        display: block;
        margin: 0;

        @include breakpoint(tablet-landscape-up) {
            display: none !important;
        }
    }
}

.panel-state-icon-col {
    > .icon-arrow-down {
        @extend .h5;
        display: block;
        transform: rotate(90deg);
        color: $brand-lightgray;
    }
}

.cart-group-item {
    > .cart-item-image-col {
    }

    > .cart-item-info-actions-col {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        @include breakpoint(tablet-landscape-up) {
            padding: 0;
            margin-left: $standard-spacing;
        }
    }
}

.cart-item-info-actions-col {
    padding: 0 10px;

    @include breakpoint(tablet-landscape-up) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 4px solid rgba($brand-blue, .5);
    }

    > .cart-item-name-col {
        flex: 1;
        flex-basis: 100%;

        @include breakpoint(tablet-landscape-up) {
            flex-basis: initial;
        }
    }

    > .cart-qty-col {
        display: flex;
        justify-content: space-between;

        @include breakpoint(tablet-landscape-up) {
        }
    }

    > .cart-item-total-price-col {
        flex: 1;
        flex-basis: 100%;
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
            flex: 0;
            flex-basis: initial;
            flex-wrap: wrap;
            padding: 0 0 0 $standard-spacing;
            min-width: 130px;
        }
    }
}

.cart-item-total-price,
.cart-item-total-price-col {
    > .full-price {
        @extend .p;
        @extend .roboto-bold;
        color: $brand-lightgray;
        display: inline-block;
        margin-right: 5px;
    }

    > .discounted-price-from {
        @extend .p;
        @extend .roboto;
        color: $brand-blue;
        text-decoration: line-through;
        display: inline-block;
        margin-right: 5px;
    }
}

.cart-item-image-col {
    > .cart-item-image {
    }
}

.cart-item-name-col {
    > .cart-item-name {
        @extend .p;
        @extend .roboto-bold;
        color: $brand-lightgray;

        @include breakpoint(tablet-landscape-up) {
            max-width: 300px;
        }
    }

    > .cart-item-unit-price {
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
        }
    }

    > .cart-item-total-price {
        @include breakpoint(tablet-landscape-up) {
            display: none;
        }
    }

    > .delete-cart-item {
        @extend .h3;
        display: none;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }
}

.cart-item-price {
    > .final-item-price {
    }

    > .initial-item-price {
    }
}

.cart-item-unit-price {
    > .final-item-price {
        @extend .p;
        @extend .roboto-bold;
        color: $brand-lightgray;
        display: inline-block;
        margin-right: 5px;
    }

    > .initial-item-price {
        @extend .p;
        @extend .roboto;
        color: $brand-blue;
        text-decoration: line-through;
        display: inline-block;
        margin-right: 5px;
    }
}

.cart-qty-col {
    > .counter-cont {
        margin-right: 25px;
        justify-content: flex-start;

        @include breakpoint(tablet-landscape-up) {
            margin: 0;
            justify-content: center;
        }
    }

    > .delete-cart-item {
        @extend .h4;

        @include breakpoint(tablet-landscape-up) {
            display: none;
        }
    }
}

.cart-item-total-price-col {
    > .full-price {
        @include breakpoint(tablet-landscape-up) {
            display: flex;
            flex-basis: 100%;
            text-align: center;
            justify-content: center;
        }
    }

    > .discounted-price-from {
        @include breakpoint(tablet-landscape-up) {
            display: flex;
            flex-basis: 100%;
            text-align: center;
            justify-content: center;
        }
    }
}

.cart-group-items-footer {
    > .cart-group-items-total {
        @extend .h5;
        @extend .roboto-bold;
        color: $brand-black;
        text-align: right;

        @include breakpoint(tablet-landscape-up) {
            display: none;
        }
    }

    > .cart-group-items-action {
        @include breakpoint(tablet-landscape-up) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}

.cart-group-items-action {


    > .add-trigger {
        margin-bottom: 20px;
        display: block;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin: 0;
        }

        .hidden-desktop {
            display: inline-block;
            margin-left: 5px;
            @include breakpoint(tablet-landscape-up) {
                display: none;
            }
        }
    }

    > .case-size {
        display: none;
        @extend .h4;
        @extend .roboto-italic;
        margin: 0;
        padding: 15px 10px 0 10px;
        flex: 1;
        color: $brand-lightgray;

        @include breakpoint(tablet-landscape-up) {
            display: flex;
        }
    }

    > .case-source {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-gray;
        text-align: center;

        @include breakpoint(tablet-landscape-up) {
            margin: 0;
        }

        > .gotocase-name {
            color: $brand-red;
        }
    }

    > .save-trigger-container {
        margin-bottom: 20px;
    }


    > .remove-trigger {
        @extend .h4;
        @extend .roboto-bold;
        text-decoration: underline;
        color: $brand-lightgray;

        @include breakpoint(tablet-landscape-up) {
            display: none;
        }

        > span {
            text-decoration: underline;
        }
    }
}

.save-trigger-container {

    > .save-trigger {

        @include breakpoint(tablet-landscape-up) {
            display: inline-block;
            align-items: center;
            justify-content: center;
            width: auto;
            margin: 0;
        }
    }

    > .login-info {
        @extend .small;
        @extend .roboto;
        font-style: italic;
        text-align: center;

        @include breakpoint(tablet-portrait-up){
            text-align: right;
        }
    }


    @include breakpoint(tablet-landscape-up) {
        text-align: right;
    }
}

.cart-subtotal-container {
    margin: 0 15px;
    border-top: rgba($brand-lightgray, 0.5) 5px solid;
    padding-top: $standard-spacing;

    @include breakpoint(tablet-landscape-up) {
        display: none;

        &.-sidebar {
            display: block;
        }
    }

    &.-cart-summary {
        margin: 0;

        @include breakpoint(tablet-landscape-up) {
            display: block;
        }
    }

    > .subtotal-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }


    .review-trigger-container {
        display: flex;
        flex-wrap: wrap;
    }
}

.subtotal-row {
    > .label-text {
        @extend .h3;
        @extend .roboto-bold;
        color: $brand-black;
    }

    > .label-text.-cart-summary {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-gray;
    }

    > .subtotal-figure {
        @extend .h3;
        @extend .roboto-bold;
        color: $brand-black;
    }

    > .subtotal-figure.-cart-summary {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-red;
    }
}

.review-trigger-container {
    > .review-trigger {
        display: flex;
        flex-basis: 100%;

        .white-bg {
            flex-basis: 100%;
            justify-content: center;
        }
    }


    > .tax-info-container {
        @extend .small;
        @extend .roboto-italic;
        flex-basis: 100%;
        color: $brand-lightgray;
        text-align: center;
    }

    > .continue-trigger {
        @extend .h4;
        @extend .roboto-bold;
        text-decoration: underline;
        color: $brand-lightgray;
        width: 100%;

        > span {
            text-decoration: underline;
        }
    }
}

.empty-cart-message-container {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-content.side-bar-open {
    .case-items-col {
        > .heading {
            display: none;
        }
    }

    .name-qty-row {
        > .panel-state-icon-col {
            display: none;
        }
    }

    .case-items-box-action {
        > .delete-cart-item {
            display: none;
        }
    }

    .cart-info-col > .case-price-row {
        display: none;
    }

    .cart-panel-heading {
        align-items: center;

        > .panel-state-icon-col {
            display: block;
        }
    }
    .cart-item-info-actions-col > .cart-item-name-col {
        flex: 1 1;
        flex-basis: 100%;
    }
    .cart-group-item > .cart-item-info-actions-col {
        display: flex;
        flex: 1 1;
        flex-wrap: wrap;
    }
    .cart-item-info-actions-col > .cart-item-total-price-col {
        flex: 1 1;
        flex-basis: 100%;
        display: none;
    }
    .cart-item-name-col > .delete-cart-item {
        display: none;
    }
    .cart-item-info-actions-col > .cart-qty-col {
        display: flex;
        justify-content: space-between;
    }
    .cart-qty-col > .delete-cart-item {
        display: block;
    }
    .cart-qty-col > .counter-cont {
        margin-right: 25px;
        justify-content: flex-start;
    }

    .cart-group-items-footer > .cart-group-items-total {
        display: block;
    }

    .cart-group-items {
        border-bottom-width: 3px;
    }
}

@media screen and (max-width: 599px) {
    .tax-info-container {
        .tax-info__mobile {
            font-size: 1rem;
        }
    }
}