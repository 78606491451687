@import 'sass/shared.scss';

.product-cont {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin-bottom: 150px;

    @include breakpoint(tablet-landscape-up) {
        margin-bottom: 100px;
    }

    .cart-btn {
        margin: 0 auto;
        margin-top: 10px;
        z-index: 9;
        @include breakpoint(phone-only) {
            padding: 0 10px;
            margin-bottom: 20px;
            width: 100%;
            font-size: $p;
        }
        @include breakpoint(phone-small) {
            font-size: 12px;
            padding: 0 5px;
        }
    }
}

.product-img-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    position: relative;
    align-items: flex-end;

    &::before {
        content: '';
        height: 250px;
        width: 250px;
        display: block;
        position: absolute;
        background: url('~Assets/images/products/product-bg.png') center 25px no-repeat;
        background-size: 200px;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
    }

    &.keg-wrapper {
        &::before {
            content: '';
            height: 250px;
            width: 250px;
            display: block;
            position: absolute;
            background: url('~Assets/images/products/blue-transparent-new.png') transparent center 25px no-repeat;
            background-size: 200px;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: 0;
            opacity: 0.5;
        }
    }

    @include breakpoint(tablet-portrait-up) {
        &::before {
            content: url('~Assets/images/products/product-bg.png');
            position: absolute;
            bottom: -50px;
            background: transparent;
            height: auto;
            width: auto;
        }
        &.keg-wrapper {
            &::before {
                background: url('~Assets/images/products/blue-transparent-new.png') center 25px no-repeat;
                position: absolute;
                bottom: -50px;
                background: transparent;
                height: auto;
                width: auto;
                opacity: 0.5;
            }
        }
    }

    &::after {
        content: url('~Assets/images/products/smoke.png');
        position: absolute;
        bottom: -50px;
    }

    &.-no-image {
        padding-top: 125px;
    }

    .no-image {
        min-height: 200px;
    }

    .product-image {
        position: relative;
        max-height: 300px;
        width: auto;
        @include breakpoint(phone-small) {
            max-height: 200px;
        }
    }
}

.product-details {
    > .product-name {
        @extend .tradesmith, .h4;
        margin-top: 20px;
        color: $brand-gray;
        display: block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include breakpoint(tablet-portrait-up) {
            min-height: 80px;
            min-height: initial;
        }
    }
    > .product-desc {
        @extend .roboto-medium, .h5;
        color: $brand-blue;
        line-height: $h5;
        display: block;
    }

    > .product-price {
        @extend .roboto-medium, .h1;
        color: $brand-gray;
        line-height: $h1;
        margin-top: 5px;
        display: block;
        @include breakpoint(phone-small) {
            font-size: 28px;
        }
    }
}

.products-list-cont {
    margin: 60px 0;
}

.filter-box {
    @include breakpoint(phone-only) {
        margin-bottom: 40px;
    }
}
