@import 'sass/shared.scss';

.product-toggle {
    width: 100%;

    @include breakpoint(tablet-portrait-up) {
        width: auto;
    }

    label.ant-radio-button-wrapper {
        @extend .tradesmith,
        .h5;
        padding: 0px 40px;
        height: 54px;
        color: $brand-lightred;
        background: transparent;
        border: 2px solid $brand-lightred;
        border-radius: 0;
        line-height: 54px;
        text-transform: uppercase;
        box-shadow: none;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        .icon {
            margin-right: 10px;
        }

        @include breakpoint(tablet-portrait-up) {
            display: inline-block;
            width: auto;

            &:last-child {
                margin-left: 10px;
            }
        }
    }

    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        color: $brand-white;
        background: $brand-lightred;
        border: 2px solid $brand-lightred;
        box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        border: 2px solid $brand-lightred;
        background: $brand-lightred;
        color: $brand-white;
    }


    .ant-radio-button-wrapper>.ant-radio-button {
        min-width: 75px;
    }
}
