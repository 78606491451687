@font-face {
    font-family: 'roboto';
    src: url('./roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto';
    src: url('./roboto-bolditalic-webfont.woff2') format('woff2'), url('./roboto-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'roboto';
    src: url('./roboto-italic-webfont.woff2') format('woff2'), url('./roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'roboto';
    src: url('./roboto-mediumitalic-webfont.woff2') format('woff2'), url('./roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
