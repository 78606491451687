@import 'sass/shared.scss';

.banner {
    width: 100%;
    background: url('~Assets/images/backgrounds/section_header.png') no-repeat center;
    background-size: cover;
    height: 60px;
    text-align: center;
    position: relative;
    .banner-title {
        @extend .tradesmith, .h2;
        @include centerer(true, false);
        display: flex;
        align-items: center;
        height: 50px;
        text-align: center;
        background: $brand-white;
        padding: 0 10px;
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.5);
    }

    @include breakpoint(phone-only) {
        background: url('~Assets/images/backgrounds/section_header_mobile.png') no-repeat center;
        height: 50px;
        background-size: cover;
        > .banner-title {
            @include font-size($h5);
            line-height: $h5;
            top: 5px;
        }
    }
}
