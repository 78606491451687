a.ant-btn,
button.ant-btn {
    @extend .tradesmith, .h5;
    padding: 0px 40px;
    height: 54px;
    color: $brand-lightred;
    background: transparent;
    border: 2px solid $brand-lightred;
    border-radius: 0;
    line-height: 54px;
    text-transform: uppercase;
    .icon-right {
        margin-left: 5px;
        font-size: 14px;
    }
    .icon {
        margin-right: 10px;
    }
    .anticon {
        margin-right: 10px;
    }
    &.ant-btn-sm {
        padding: 0 20px;
        height: 34px;
        line-height: 31px;
        @extend .p;
    }
    &:hover,
    &:focus,
    &:active {
        border: 2px solid $brand-lightred;
        background: $brand-lightred;
        color: $brand-white;
    }
    &.-filled {
        border: 2px solid $brand-lightred;
        background: $brand-lightred;
        color: $brand-white;
    }
}

button.-transparent,
.ant-btn.-transparent {
    background: transparent;
    border: 0px;

    &:hover,
    &:focus,
    &:active {
        color: initial;
        background: transparent;
        border: 0px;
    }
}

.social-media-col {
    .btn-social {
        @extend .roboto;
        border-radius: 4px;
        color: white;
        border: 0px transparent;
        text-align: center;
        text-transform: initial;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.-fb {
            background: #3b5998;
        }

        &.-google {
            background: #db3236;
        }
    }
}

.btn-social.-fb {
    .icon {
        display: flex;
        font-size: $h4;
        margin-right: 30px;
        line-height: 54px;
    }
}

.btn-social.-google {
    .icon {
        display: flex;
        font-size: $h3;
        margin-right: 30px;
        line-height: 54px;
    }
}

.ant-pagination {
    @extend .roboto-medium;

    > .ant-pagination-item {
        background: transparent;
    }
    > .ant-pagination-item-active {
        border-color: $brand-red;
        a {
            color: $brand-red;
        }
    }
    .ant-pagination-item-active,
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
        border-color: $brand-red;
    }

    .ant-pagination-item-active a,
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
        color: $brand-red;
    }

    .ant-pagination-next:hover .ant-pagination-item-link {
        color: $brand-red;
        border-color: $brand-red;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link {
        color: $brand-red;
        border-color: $brand-red;
    }
}

.user-action-trigger {
    background: transparent;
    border: none;
}

.red-filled-btn {
    @extend .roboto-bold;
    padding: 0 20px;
    height: 34px;
    line-height: 31px;
    border: 2px solid $brand-lightred;
    background: $brand-lightred;
    color: $brand-white;
    text-transform: uppercase;
    text-decoration: none;
    .icon {
        margin-right: 10px;
        font-size: 12px;
    }
    &:hover,
    &:focus,
    &:active {
        border: 2px solid $brand-lightred;
        background: $brand-lightred;
        color: $brand-white;
    }
}
