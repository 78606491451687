@import 'sass/shared';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.app-hero {
	background: url('~Assets/images/backgrounds/dots.png') 0 0 repeat;
	display: flex;
	justify-content: center;
	overflow: hidden;
	position: relative;
	height: 75vh;
	margin-top: 0;
	padding-top: 0;

	@include breakpoint(phone-only) {
		height: 65vh;
	}
	@include breakpoint(tablet-portrait-up) {
		padding-top: 110px;
	}

	@include breakpoint(desktop-up) {
		min-height: 50vh;
	}

	.gradient-btn.-large {
		.white-bg {
			font-size: 25px;
			height: 50px;
			@include breakpoint(phone-only) {
				height: 30px;
				padding: 0 15px;
				font-size: 25px;
			}
		}
	}

	> .hero-bg-container {
		width: 100%;
		background: url('~Assets/images/default-magnets/3Nov22 Homepage Magnets Mobile.png') no-repeat;
		background-size: contain;
		background-position: center top;
		&.normal {
			background-image: url('~Assets/images/default-magnets/3Nov22 Homepage Magnets Mobile.png');
			background-position: center -15px;
		}
		@include breakpoint(desktop-up) {
			background: url('~Assets/images/default-magnets/13Oct22HomepageMagnetsDesktop.png');
			background-position: center;
			background-size: cover;
			&.normal {
				background-image: url('~Assets/images/default-magnets/13Oct22HomepageMagnetsDesktop.png');
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
			}
		}
	}

	> .hero-handle {
		position: absolute;
		left: 20px;
		top: 50%;
		margin-top: -20px;
		width: 30px;
		height: auto;
		z-index: 3;

		@include breakpoint(tablet-landscape-up) {
			position: absolute;
			left: 20px;
			top: 50%;
			width: 70px;
			height: auto;
			margin-top: 0;
			transform: translate(0, -50%);
		}
	}

	&:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 50px;
		left: 0;
		top: 0;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6fb4cc+0,7db9e8+100&0.62+0,0+100 */
		background: -moz-linear-gradient(left, rgba(111, 180, 204, 0.62) 0%, rgba(125, 185, 232, 0) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(111, 180, 204, 0.62) 0%, rgba(125, 185, 232, 0) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(111, 180, 204, 0.62) 0%, rgba(125, 185, 232, 0) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9e6fb4cc', endColorstr='#007db9e8', GradientType=1);
		/* IE6-9 */
	}
}

.hero-bg-container {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	top: 0;
}

.hero-bg-image-container {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;

	&.-desktop {
		display: none;
	}

	&.-mobile {
		display: flex;
	}

	@include breakpoint(tablet-landscape-up) {
		&.-desktop {
			display: flex;
		}

		&.-mobile {
			display: none;
		}
	}
}

.hero-message-container {
	padding: 45px 50px 100px;
	margin: 0;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 500px;
	flex-wrap: wrap;

	@include breakpoint(tablet-landscape-up) {
		padding: 0px 50px;
		margin: 20px 0;
	}

	> .hero-message-image {
		width: 100%;
		max-width: 500px;
		height: auto;
		margin-bottom: 30px;
		display: none;
	}

	.gradient-btn {
		position: absolute;
		bottom: 25%;

		@include breakpoint(tab-port) {
			bottom: 37%;
		}

		@include breakpoint(tablet-landscape-up) {
			bottom: 40px;
		}

		@include breakpoint(phone) {
			bottom: 32%;
		}
	}
}

.reco-products {
	background: #ccc url('~Assets/images/backgrounds/noise.png') repeat;
	padding: 40px 0;
}

.best-products {
	background: #ccc url('~Assets/images/backgrounds/noise.png') repeat;
	padding: 40px 0;
}

.reco-products-container {
	text-align: center;
	overflow: hidden;
}

.best-products-container {
	text-align: center;
}

.hero-bottom-border {
	background: #ccc url('~Assets/images/sub-footer-lines.png') repeat;
	width: 100%;
	display: flex;
	height: 32px;
}

.hero-notice-section {
	display: flex;
	justify-content: center;
	padding: 0 15px;
}

section.notice {
	background: #ccc url('~Assets/images/backgrounds/noise.png') repeat;
	padding: 40px 0;
	text-align: center;
	.account-notice {
		max-width: 800px;
		width: 100%;
		background: $brand-white;
		margin: 0 auto;
		padding: 20px;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
		h1 {
			color: $brand-red;
			font-size: 24px;
			font-weight: bold;
		}
		p {
			font-size: 16px;
		}
		a {
			color: $brand-red;
		}
	}
}
.smb-modal-body {
	.success-title {
		@extend .roboto-bold, .h1;
		text-align: center;
		margin-bottom: 20px;
		color: $brand-yellow;
		text-transform: uppercase;
	}
	.success-paragraph {
		@extend .roboto-bold;
	}
}

.saved-address {
	position: relative;
	// z-index: 1000;
	margin: 150px 0px 10px 0px;
	// left: 10%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.arrow-down {
	border: solid #f8a500;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	margin-left: 4px;
	margin-bottom: 2px;
	transform: rotate(45deg);
  	-webkit-transform: rotate(45deg);
}

@media (max-width: 599px) {
	.saved-address {
		margin-top: 75px;
	}
}