.section__whatsnew {
    width: 100%;    
    &--col {
        width: 70%;
        margin: 0 auto;
        margin-top: 13px;
        margin-bottom: 13px;
    }

    &--title {
        h3 {
            text-transform: uppercase;
            text-align: center;
            font-size: 30px;
            font-weight: bolder;

            @include breakpoint(phone) {
                font-size: 15px;
            }
        }
    }

    &--image {
        margin: 0 auto;
        img {
            display: block;
            width: 50%;
            margin: 0 auto;
        }
    }
}

@media (max-width: 480px) {
	.section__whatsnew {
        &--image {
            img {
                width: 100%;
            }
        }
    }
    
}