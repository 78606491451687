@import 'sass/shared';

$bg-white: #fff;

.ant-layout-header.app-header {
    background: transparent;
    padding: 0;
    height: auto;
    line-height: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.logo {
    color: #fff;
    padding: 14px;
    height: 100%;

    .semi-logo {
        width: 163px;
        height: 35px;
        background: $bg-white;
        display: flex;
        align-items: center;
    }
}

.menu-icon {
    margin-left: 10px;
}

.ant-layout-header {
    background: $bg-white;
}

.ant-menu {
    .ant-menu-item {
        // height: 100%;
        // padding: 0 25px;
        // display: flex;
        // align-items: center;
    }
}

.top-header {
    background: #ccc url('~Assets/images/backgrounds/lines-darker.png') repeat;
    display: flex;

    >.header-sub-menu {
        flex: 1;
    }

    @include breakpoint(phone-only) {
        display: none;
    }
}

.header-sub-menu {
    >.header-sub-menu-links {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
}

.header-sub-menu-links {
    @extend .lists.-inline;
    margin: 0;
}

.header-sub-menu-links {
    >.header-sub-menu-links-item {}
}

.header-sub-menu-links-item {
    >.header-sub-menu-link {
        @extend .tradesmith;
        @extend .h3;
        color: $brand-gray;
        transition: 0.3s ease all;
        display: block;
        padding: 5px 15px;
    }

    &:hover {
        >.header-sub-menu-link {
            color: $brand-yellow;
        }
    }
}

.main-header {
    background: transparent;
    position: relative;

    >.centered-container {
        position: relative;
    }

    &:before {
        content: '';
        background: #ccc url('~Assets/images/backgrounds/lines.png') repeat;
        width: 100%;
        height: 75px;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: #e5e5e5 0 6px 1px;

        @include breakpoint(phone-only) {
            height: 55px;
        }
    }
}

.main-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >.logo-container {}

    >.user-actions-container {}
}

.mobile-menu-container {
    display: flex;
    flex-grow: 1;
    flex: initial;
    flex-basis: 30%;

    @include breakpoint(desktop-up) {
        display: none;
    }
}

.mobile-hamburger {
    display: block;
    width: 60px;
    padding: 10px 15px;
    background: transparent;
    border: 0px;

    >.patty {
        display: block;
        margin-bottom: 3px;
        height: 3px;
        width: 100%;
        background-color: $brand-yellow;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.main-menu {
    margin-left: 15px;
    display: none;
    flex-grow: 1;
    flex-basis: 30%;

    @include breakpoint(tablet-landscape-up) {
        margin-top: -15px;
    }

    @include breakpoint(desktop-up) {
        display: flex;
    }
}

.main-menu-links {
    @extend .lists.-inline;
    justify-content: flex-start;

    >.main-menu-links-item {
        display: flex;
    }
}

.main-menu-links {
    padding-top: 1.5rem;
    white-space: nowrap;
    >.main-menu-links-item {}
}

.main-menu-links-item {
    >.main-menu-link {
        @extend .tradesmith;
        @extend .h3;
        color: $brand-yellow;
        transition: 0.3s ease all;
        display: block;
        padding: 5px 30px;
    }

    &:hover {
        >.main-menu-link {
            color: $brand-gray;
        }
    }
}

.logo-container {
    display: flex;
    margin: -8px;
    flex-basis: 40%;
    justify-content: center;

    @include breakpoint(phone-only) {
        margin: 0;
        flex: initial;
        justify-content: center;
    }

    @include breakpoint(desktop-up) {
        margin-right: 225px;
    }
}

.site-logo-link {
    display: block;

    >.site-logo {
        @include breakpoint(phone-only) {
            display: block;
            width: 100%;
            max-width: 116.34px;
            height: 54.44px;
        }
    }
}

.user-actions-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    flex-basis: 30%;


    @include breakpoint(phone-only) {
        flex: initial;
        flex-basis: 30%;
    }

    @include breakpoint(tablet-landscape-up) {
        margin-top: -15px;
    }


    >.user-action-container {
        // margin: 0 10px;

        @include breakpoint(tablet-portrait-up) {
            margin: 0 10px;
        }
    }
}

.user-action-container {
    >.user-action-trigger {
        background: transparent;
        border: 0px;
        display: inline-flex;
        align-items: center;
        >.icon-user, >.user-firstname {
        }
        >.user-firstname {
            color: #f8a500;
            transition: 0.3s ease all;
            display: block;
            padding: 5px 30px;
            font-size: 1.5rem;
            font-family: "tradesmithrough", sans-serif;
        }
        // @include breakpoint(phone-only) {
        //     >.user-firstname {
        //         display: none;
        //     }
        // }
    }

    >.user-action-trigger.-icon {
        @extend .h3;
        color: $brand-yellow;
        padding: 7px 15px 5px;

        @include breakpoint(tablet-landscape-up) {
            font-size: $h2;
        }
    }
}

@media (max-width: 1200px) {
    .user-action-container {
        >.user-action-trigger {
            >.user-firstname {
                display: none;
            }
        }
    }
}

// APP HEADER FOR MAINTENANCE PAGE
.app-root-container.-simple {
    >.ant-layout-header.app-header {

        & .top-header,
        & .main-menu,
        & .user-actions-container,
        & .mobile-menu-container {
            display: none;
        }

        & .logo-container {
            flex-basis: 100%;
        }
    }
}
