@import 'sass/shared.scss';

.cart-group-items {
    padding: 0 0 $standard-spacing * 3;
    list-style: none;
    border-bottom: rgba($brand-lightgray, 0.5) 5px solid;

    > .cart-group-item {
    }
}

.cart-group-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: $standard-spacing 0;

    @include breakpoint(tablet-landscape-up) {
        align-items: initial;
    }

    // &:after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     margin-left: -1 * 180px / 2;
    //     width: 180px;
    //     height: 4px;
    //     background-color: rgba($brand-blue, .5);

    //     @include breakpoint(tablet-landscape-up){
    //         display: none;
    //     }
    // }
}

.cart-item-image-col {
    display: flex;
    align-items: center;
    min-width: 100px;
    justify-content: center;
    @include breakpoint(phone-small) {
        min-width: 50px;
    }
    > .cart-item-image {
        max-height: 180px;
        max-width: 85px;
        width: auto;
        @include breakpoint(phone-small) {
            max-width: 60px;
        }
    }

    > .no-image {
        height: 180px;
        width: 100%;
        max-width: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
    }
}
