@import 'sass/shared';

.location-modal {
  text-align: center;
  .location-title {
    @extend .roboto-bold, .h1;
    text-align: center;
    margin-bottom: 20px;
  }
  .icon-location {
    font-size: 100px;
    color: $brand-yellow;
    margin-bottom: 20px;
    display: block;
  }

  .location-select {
    width: 100%;
    margin-bottom: 10px;
    @extend .roboto-bold;
    .ant-select-selection {
      border: 2px solid $brand-yellow;
      border-radius: 25px;
      svg {
        color: $brand-yellow;
      }
    }
    ul {
      @extend .roboto-bold;
    }
  }

  .agree-btn {
    margin-bottom: 10px;
  }
  .form-container {
    padding: 20px 0;
  }
}
