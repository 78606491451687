#service-option {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 35px 0 35px 0;
}

.fulfillment-name:hover {
    color: #f8a500;
}

.continue:hover {
    color: #f8a500;
}

.dropdown-option {
    position: absolute;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
}

#continue-button {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 50px 0px 35px 0px;
}

// #dropdown-description {
//     text-align: left;
// }

.select-head {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 30px;

    @media (max-width: 25em) {
        margin-left: 2px;
    }

    @media (max-width: 25em) {
        margin-left: 2px;
    }
}

.select-head-store {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 20px;
}

// .location-modal-container {
//     position: relative;
// }

.container {
    display: block;
    align-items: center;
    justify-content: center;
    width: 50% auto;
    // max-width: 100%;
    // position: relative;

    &__items {
        align-items: left;
        justify-content: left;
        text-align: left;
        margin: 0 auto;
        max-width: 80%;
    }
}

.use-saved-address {
    text-decoration: underline;
    color: rgb(197, 49, 49);
    font-size: small;
}

.delivery-note {
    font-size: 11.5px;
}

.barangay-select {
    margin: 20px 0 0 0;
}

.location-gate-description {
    text-align: center;
    font-weight: bold;
    font-size: large;
    text-transform: uppercase;
    font-family: 'tradesmithrough';
}

.delivery {
    &__note {
        max-width: 100%;
        text-align: left;
        &--text{
            display: block;
        }
    }
}
@media (max-width: 410px) {
    #service-option {
      display: block !important;
      text-align: center !important;
    //   margin-bottom: 10px;
      .gradient-btn {
        display: block !important;
        text-align: center !important;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

.select-barangay, .select-city {
    width: '350px';
    // width: 100%;
}

@media (max-width: 400px) {
    .select-barangay, .select-city {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 401px) {
    .select-barangay, .select-city {
        width: 337px;
        margin: 0 auto;
    }
}

@media (max-width: 360px) {
    #continue-button {
        width: 100% !important;
        .gradient-btn {
            width: 100%;
             .continue-button {
                margin: 0 0;
             }
        }
    }
}


@media(min-width: 361px) {
    .continue-button {
        width: 260px;
        margin: 0px 0px;
    }
}

@media(min-width: 400px) {
    .continue-button {
        width: 285px;
    }
}

@media (min-width: 460px) {
    #service-option {
        padding: 0 30px;
    }
}

@media (min-width: 500px) {
    #service-option {
        padding: 0 45px;
    }
}

@media (min-width: 550px) {
    .select-head {
        padding: 0 30px;
    }
}

@media (min-width: 550px) {
    .select-head-store {
        padding: 0 9px;
    }
}

@media (min-width: 650px) {
    .select-head {
        padding: 0 28px;
    }
}

@media (min-width: 650px) {
    .select-head-store {
        padding: 0 8px;
    }
}

@media (min-width: 340px) {
    .select-head-store {
        margin-left: 5px;
    }
}

@media (min-width: 400px) {
    .select-head-store {
        margin-left: 15px;
    }
}

@media (min-width: 430px) {
    .select-head-store {
        margin-left: 25px;
    }
}

@media (min-width: 450px) {
    .select-head-store {
        margin-left: 30px;
    }
}

@media (min-width: 460px) {
    .select-head-store {
        margin-left: 40px;
    }
}

@media (min-width: 470px) {
    .select-head-store {
        margin-left: 50px;
    }
}