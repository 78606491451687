@import 'sass/shared.scss';

.gradient-btn {
    background: rgb(248, 165, 0);
    background: linear-gradient(
        8deg,
        rgba(248, 165, 0, 1) 20%,
        rgba(254, 232, 104, 1) 31%,
        rgba(254, 232, 104, 1) 37%,
        rgba(248, 165, 0, 1) 47%,
        rgba(248, 165, 0, 1) 53%,
        rgba(254, 232, 104, 1) 63%,
        rgba(254, 232, 104, 1) 69%,
        rgba(248, 165, 0, 1) 80%
    );
    border: none;
    color: $brand-red;
    @extend .tradesmith, .h5;
    padding: 5px;
    line-height: normal;
    cursor: pointer;
    display: inline-flex;

    &.-block {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        width: 100%;
        .white-bg {
            width: 100%;
            justify-content: center;
        }
    }

    &[disabled] {
        opacity: .6;
        cursor: initial;
    }
}

.white-bg {
    background: $brand-white;
    display: inline-flex;
    align-items: center;
    line-height: normal;
    height: 40px;
    padding: 0 20px;
    width: 100%;
    justify-content: center;
}

.gradient-btn.-large {
    @extend .h1;
    .white-bg {
        padding: 0 30px;
        height: 80px;
    }
}

.gradient-btn.-block[disabled] {
    opacity: 0.6;
    cursor: default;
  }
