.ant-form-item {
    margin-bottom: 10px;
}

.ant-input,
.ant-select-selection {
    @extend .roboto, .p;
    padding: 10px;
    height: 50px;
    border: 1px solid $brand-gray;
    border-radius: 0px;
}

.ant-select-lg .ant-select-selection--single {
    padding: 0;
}

.ant-form-item-label > label {
    @extend .roboto-medium, .h5;
    color: $brand-gray;

    .required-asterisk {
        color: $brand-red;
    }
}


.react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
        width: 100%;
        display: block;
    }

    .date-picker {
        @extend .roboto, .p;
        display: block;
        width: 100%;
        padding: 10px;
        height: 50px;
        border: 1px solid $brand-gray;
        border-radius: 0px;
    }
}

.rc-time-picker {
    .rc-time-picker-input {
        @extend .roboto, .p;
        display: block;
        width: 100%;
        padding: 10px;
        height: 50px;
        border: 1px solid $brand-gray;
        border-radius: 0px;
    }

    .rc-time-picker-clear{
        display: none;
        background-color: #216ba5;
        border-radius: 50%;
        box-sizing: border-box;
        color: #fff;
        height: 16px;
        width: 16px;
        top: 25%;
    }

    .rc-time-picker-clear::after {
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: "\D7";
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
    }

    .rc-time-picker-clear-icon {
        display: none;
    }
}


.ant-checkbox-wrapper.terms-checkbox {
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    margin-bottom: $standard-spacing;

    > .ant-checkbox {
        margin-top: 5px;
    }
}
