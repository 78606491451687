@import 'sass/shared.scss';

.saved-address-modal {
    display: block;
    text-align: center;
}

.saved-address-header {
    text-align: center;
    font-weight: bold;
    font-size: large;
    text-transform: uppercase;
    font-family: 'tradesmithrough';
    color: #332d2d;
}

.address-items-list {
    display: inline-block;
}

.address-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border: 2px solid #525252;
    margin-top: 3.5px;
    width: 300px;
    cursor: pointer;
}

.address-item:hover {
    background-color: #fee868;
}

.address-item:active {
    background-color: #f8a500;
}

.use-address-button {
    margin-top: 20px;
    width: 300px;
    margin-bottom: 40px;
}

.use-address-button:hover {
    color: #f8a500;
}

.icon {
    color: $brand-yellow;
    font-size: 40px;
}

.icon-next {
    color: $brand-yellow;
    font-size: $h5;
}

@media (max-width: 350px) {
    .address-item {
        width: 100%;
    }
    .use-address-button {
        width: 100%;
    }
}