@mixin font-size($sizeValue: 16px) {
    font-size: $sizeValue;
    font-size: ($sizeValue / 16px) + rem;
}

@mixin footer-for-desktop-up {
    .sub-footer {
        background: #ccc url('~Assets/images/backgrounds/lines-darker.png') repeat;
    
        &:after {
            content: '';
            background: #ccc url('~Assets/images/sub-footer-lines.png') repeat;
            width: 100%;
            display: flex;
            height: 32px;
        }
    
        > .centered-container {
        }
    }
}

@mixin breakpoint($size) {

    @if $size == phone {
        @media (max-width: 37.5em) { // 600px
            @content;
        }
    }

    @if $size == tab-port {
        @media (max-width: 56.25em) { // 900px
            @content;
        }
    }


    @if $size == phone-only {
        @media (max-width: 599px) {
            @content;
        }
    } @else if($size == tablet) {
        @media (max-width: 600px) {
            @content;
        }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 600px) {
            @content;
        }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 900px) {
            @content;
        }
    } @else if $size == desktop-up {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $size == big-desktop-up {
        @media (min-width: 1800px) {
            @content;
        }
    } @else if $size == phone-small {
        @media (max-width: 400px) {
            @content;
        }
    }
}

@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;
    @if $horizontal and $vertical {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    } @else if $horizontal {
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    } @else if $vertical {
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
}


@mixin clearfix {
    content: "";
    display: table;
    clear: both;
}


@mixin flexby($flex) {
    display: flex;
    flex-direction: $flex;
}