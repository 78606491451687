@import 'sass/shared.scss';

.hero-notice {
    box-shadow: none;
    z-index: 1;
    display: block;
    text-align: center;
    width: 80%;
    max-width: 600px;
    padding: 10px;
    background: $brand-white;

    > h5 {
        // @extend .tradesmith;
        color: #c70317 !important;
        font-weight: bold;
        font-size: $h3;
        color: $brand-yellow;
        @include breakpoint(phone-only) {
            font-size: 14px;
        }
    }

    > p {
        @extend .roboto, .p;

        @include breakpoint(phone-only) {
            font-size: 12px;
        }

        a {
            @extend .roboto-medium;
            // text-decoration: underline;
            color: #c70317 !important;
        }
    }
}
