@import 'sass/shared.scss';

.delivery-banner {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  background: white;
  font-family: $roboto;
  font-weight: bold;
  z-index: 9;
  button {
    border: none;
    background: transparent;
    font-weight: bold;
    text-transform: uppercase;
    color: $brand-yellow;
    cursor: pointer;
  }
  @include breakpoint(tablet-portrait-up) {
    padding-top: 40px;
  }
}
