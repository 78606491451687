@import 'sass/shared';

.loading-cont {
    justify-content: center;
    align-items: center;

    &.-active {
        display: flex;
        flex: 1;
    }
}

.loader-box {
    height: 100px;
    // max-width: 300px;
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);

    > .message {
        font-weight: bold;
    }
}

.loader {
    display: inline-block;
    position: relative;
    width: 54px;
    height: 54px;
  }
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 41px;
    height: 41px;
    margin: 6px;
    border: 6px solid $brand-yellow;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $brand-yellow transparent transparent transparent;
  }
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
