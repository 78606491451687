h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $roboto;
}

a {
    color: $brand-yellow;

    &:hover {
        color: $brand-yellow;
    }
}

h1.ant-typography,
.ant-typography h1 {
    @extend .h1;
}

h2.ant-typography,
.ant-typography h2 {
    @extend .h2;
}

h3.ant-typography,
.ant-typography h3 {
    @extend .h3;
}

h4.ant-typography,
ant-typography h4 {
    @extend .h4;
}

h5.ant-typography,
ant-typography h5 {
    @extend .h5;
}
