@import 'sass/shared.scss';

.ant-layout-footer.app-footer {
    padding: 0;
    height: auto;
    line-height: 1;
}

.main-footer {
    padding: 130px 0;
    background: #ccc url('~Assets/images/backgrounds/lines.png') repeat;

    @include breakpoint(tablet-portrait-up) {
        padding: 50px 15px;
    }

    @include breakpoint(phone-only) {
        padding: 25px 15px;
    }
}

.footer-column-content {
    @include breakpoint(phone-only) {
        padding: 0 0 30px;
    }
}

.footer-column-heading-container {
}

.footer-column-title {
    @extend .tradesmith;
    @extend .h3;
    color: $brand-yellow;
    margin-bottom: 10px;
}

.footer-column-body {
    > .mailing-list-promo {
        @extend .roboto-medium;
        @extend .p;
    }
}

.mailing-list-form {
    > .form-submission-result {
        @extend .roboto;
        @extend .p;
        color: $brand-success;
        margin: 0;
        padding: 10px 5px 0;

        &.-error {
            color: $brand-red;
            font-weight: bold;
        }
    }
}

.subscribe-input {
    @extend .roboto-medium;
    @extend .p;
    box-shadow: rgba(87, 91, 93, 0.2) 2.5px 4.3px 0px;
    line-height: 60px;
    height: 60px;
    border: 0px;
    padding: 0 40px 0 20px;
    width: 100%;

    &::placeholder {
        @extend .roboto-medium-italic;
    }
}

.subscribe-btn {
    margin-top: 20px;
    .white-bg {
        height: 30px;
    }
}

.footer-company-logo-container {
}

.footer-company-contact-container {
    a {
        @extend .roboto-medium, .p;
        color: $brand-gray;
        font-size: 11px;

        @include breakpoint(phone-small) {
            font-size: 9px;
        }
    }

    .anticon {
        color: $brand-gray;
        margin-right: 10px;
    }

    .contact-details {
        margin-bottom: 10px;
    }
}

.footer-list {
    @extend .lists.-unstyled;

    > .footer-list-item {
    }
}

.footer-list-item {
    > .footer-list-link {
        display: block;
        margin-bottom: 10px;
    }
}

.footer-list-link {
    @extend .roboto-medium;
    @extend .p;
    color: $brand-gray;
}

.drink-responsibly-logo {
    width: 100%;
    height: auto;
    max-width: 150px;
}

.pre-footer {
    background: #ccc url('~Assets/images/backgrounds/lines-darker.png') repeat;
    display: flex;
    justify-content: center;
    padding: 15px 0;

    > .footer-ref-accent {
        width: 100%;
        max-width: 900px;
        height: auto;
        padding: 0 15px;
    }
}

.centered-container {
    > .sub-footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;

        @include breakpoint(tablet-portrait-up) {
            padding: 30px 15px;
        }

        @include breakpoint(phone-only) {
            padding: 30px 15px;
            flex-wrap: wrap;
        }
    }
}

.sub-footer-content {
    > .copyright-container {
        margin: 0;

        @include breakpoint(phone-only) {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    > .social-media-links {
        display: flex;

        @include breakpoint(phone-only) {
            width: 100%;
            justify-content: center;
        }
    }
}

.copyright-container {
    @extend .roboto-medium;
    @extend .small;
}

.social-media-links.-footer {
    list-style: none;
    padding: 0;
    margin: 0;

    .social-media-list-item {
        display: inline-block;
    }
}

.social-media-list-item {
    > .social-media-link.-footer {
    }
}

.social-media-link {
    @extend .h4;
    padding: 0 15px;
    color: $brand-gray;
}

// APP Footer FOR MAINTENANCE PAGE
.app-root-container.-simple {
    > .ant-layout-footer.app-footer {
        & .main-footer,
        & .sub-footer {
            display: none;
        }
    }
}

.find-a-store {
    letter-spacing: 0.15em;
    font-size: 1.5rem;
}

.input-caret {
    display: flex;
    justify-content: center;
    align-items: center;
}

.caret {
    position: absolute;
    font-size: $h3;
    color: #c70317;
    margin-left: 330px;
}

.footer-column-content {
    &__logo {
        display: none;
        width: 100%;
        height: auto;
        max-width: 150px;
    }
}

@include footer-for-desktop-up;

@media (max-width: 318px) {
    .caret {
        margin-left: 245px;
    }

}

@media (min-width: 319px) and (max-width: 349px) {
    .caret {
        margin-left: 270px;
    }    
}

@media (min-width: 350px) and (max-width: 380px) {
    .caret {
        margin-left: 300px;
    }
    
}


@media (min-width: 400px) and (max-width: 450px) {
    .caret {
        margin-left: 350px;
    }    
}

@media (min-width: 451px) and (max-width: 480px) {
    .caret {
        margin-left: 375px;
    }    
}

@media (min-width: 481px) and (max-width: 500px) {
    .caret {
        margin-left: 400px;
    }    
}

@media (min-width: 501px) and (max-width: 520px) {
    .caret {
        margin-left: 420px;
    }    
}

@media (min-width: 521px) and (max-width: 560px) {
    .caret {
        margin-left: 460px;
    }    
}

@media (min-width: 561px) and (max-width: 600px) {
    .caret {
        margin-left: 500px;
    }    
}

@media (min-width: 601px) and (max-width: 670px) {
    .caret {
        margin-left: 560px;
    }
}

@media (min-width: 671px) and (max-width: 767px){
    .caret {
        margin-left: 600px;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .caret {
        margin-left: 340px;
    }

}

@media (min-width: 901px) and (max-width: 1000px) {
    .caret {
        margin-left: 360px;
    }
}

@media (min-width: 1001px) and (max-width: 1199px)  {
    .caret {
        margin-left: 400px;
    }
}


@media (max-width: 599px) {
    .san-miguel-brewery {
        text-align: center;
    }
    .drink-responsibly-logo {
        visibility: hidden;
    }

    .footer-column-content {
        &__logo {
            display: block;
        }
    }

    .sub-footer {
    
        &:after {
            display: none;
        }
    
        > .centered-container {
        }
    }
}
