.centered-container {
    width: 100%;
    max-width: $desktop-container;
    margin: 0 auto;
}

@media screen and (max-width: 599px) {
    .mobile-text {
        font-size: 1rem !important;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row--item-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.-color-red {
    color: red;
}

.float-right {
    float: right;
}