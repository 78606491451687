@import 'sass/shared.scss';

.reco-products-container {
    .slick-track {
        display: flex;
        align-items: flex-end;
    }

    .product-cont {
        margin-bottom: 0;
    }
}
.slick-prev,
.slick-next {
    z-index: 9;
    width: 25px;
    height: 25px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 25px;
    color: $brand-red;
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;
}
