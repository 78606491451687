@import 'sass/shared.scss';

html,
body {
    font-size: 16px;

    @include breakpoint(tablet-portrait-up) {
        font-size: 14px;
    }

    &.-hidden {
        overflow: hidden;
    }
}

.app-root-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main-content {
    @extend .roboto;
    background: #ffffff;
    // min-height: calc(100vh - 68px - 64px); //to remove black space when recommended products are not visible.
    position: relative;
    // font-family: 'Nunito-Regular';
    // padding-top: 110px;
    display: flex;
    flex-direction: column;
}

.drawer-layout-container {
    position: relative;

    .ant-drawer {
        position: relative;
    }
}

.ant-drawer {
    &.drawer-container {
        overflow: hidden;

        .ant-drawer-header {
            .ant-drawer-title {
                @extend .roboto-bold;
                color: $brand-white;
                text-transform: uppercase;
            }

            background: $brand-yellow;
        }

        .ant-drawer-content-wrapper {
            width: 100% !important;

            @include breakpoint(tablet-portrait-up) {
                width: 430px !important;
            }
        }

        .ant-drawer-wrapper-body {
            overflow-x: hidden !important;
            overflow-y: auto !important;
        }

        .ant-drawer-close {
            color: $brand-white;
        }

    }

    &.ant-drawer-right.ant-drawer-open {
        position: absolute;
    }

    .ant-drawer-mask {
        position: absolute;
    }

    .ant-drawer-content-wrapper {
        // position: absolute;
    }
}

.ant-drawer-body {
    > .tax-info-container {
        @extend .small;
        @extend .roboto-italic;
        color: $brand-lightgray;
        text-align: center;
        margin-bottom: 20px;
    }
}


.loading-overlay {
  position: fixed;
  display: flex;
  /* align-items: flex-start; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.75);
}



strong.dates {
    text-decoration: underline;
}
