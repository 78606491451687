.howtoorder {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    &__row {
        max-width: 50%;

        @include breakpoint(tab-port) {
            max-width: 86%;
        }

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phone-only) {
            max-width: 100%;
        }
        
    }

    &_col {
        margin-bottom: 1.8rem;
    }

    &__description{
        font-size: 1rem;
        @include breakpoint(phone-only) {
            font-size: .8rem;
        }
        
    }

    &__step {

        // p {
        //     font-size: 1rem;
        // }
        &--counter {
            width: 5%;

            @include breakpoint(tablet) {
                max-width: 100%;
            }
            
            @include breakpoint(phone-only) {
                width: 6%;
            }
    
        }        

        &--main-title {
            display: inline-flex;
            text-transform: uppercase;
            font-weight: bolder;
            margin-left: 10px;
            padding-left: 2px;

            @include breakpoint(tab-port) {
                font-size: small;
            }
            @include breakpoint(tablet) {
                max-width: 100%;
            }

            @include breakpoint(phone-only) {
                font-size: 8px;
            }
    
        }

        &--content {
            // display: inline-flex;
            margin-left: 57px;

            @include breakpoint(phone-only) {
                margin-left: 9%;
            }
            
        }

        &-4 {
            display: flex;
            flex-direction: row;

            @include breakpoint(tablet) {
                @include flexby(column);
            }

            @include breakpoint(phone-only) {
                @include flexby(column);
            }
    
            
            &--left-img {
                img {
                    width: 95%;
                }
            }

            &--right-img {

                @include flexby(column);

                @include breakpoint(tablet) {
                    @include flexby(row);
                }

                @include breakpoint(phone-only) {
                    @include flexby(column);
                }

                img {
                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                    width: 95%;
                }
            }
        }
    }

    &__img {
        // img {
        //     &:not(:last-child){
        //         // margin-right: 1rem;
        //     }
        //     width: 48%;

        //     @include breakpoint(phone-only) {
        //         width: 80%;
        //     }

        // }

        &--step-7{
            justify-content: center;
            align-items: center;

            &-img1 {
                @include breakpoint(phone-only) {
                    margin-right: 0;
                }
            }
            @include breakpoint(phone-only) {
                justify-content: left;
                width: 80%;
            }
        }
    }

    .flex-row--item-center {
        img {
            &:not(:last-child){
                margin-right: 0 !important;
            }
            width: 30%;
        }
    }
}

.flex-cont {
    @include flexby(row);

    @include breakpoint(phone-only) {
        @include flexby(column);
    }

}

.step__image {
    width: 48%;

    @include breakpoint(phone-only) {
        width: 80%;
    }

    &-1 {
        margin-right: 1rem;
    }
}

.step__image7 {
    width: 30%;
}