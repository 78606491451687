@import 'sass/shared.scss';

.container {
    // display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    // background-color: white;
    // border: 1px solid black;
    padding: 10px 10px;
}

.element {
    max-width: 180px; height: auto;
    margin: 0 auto;
    text-align: center;
}

.product-type:hover {
    color: #f8a500;
}

.text {
    height: 180px;
    font-size: 16px;
    text-align: justify;
    // background: white;
}

@media (max-width: 800px) {
    .text   {
        font-size: .9rem;
    }   
}

@media (max-width: 600px) {
    .product-img-container::before {
        height: 250px !important;
        width: 250px !important;
        bottom: 0 !important;
    }
    .product-img-container.keg-wrapper {
        &::before {
            height: 310px !important;
            width: 330px !important;
            top: -45px;
            left: 50%;
            background-size: 210px !important;
            opacity: 0.5;
        }
        &::after {
            bottom: -50px;
        }
    }
}

@media (max-width: 503px) {
    .container {
        margin-top: 15px;
    }
}

.product-img-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    position: relative;
    align-items: flex-end;
    height: 260px;
    margin-bottom: 20px;

    &::before {
        content: '';
        height: 250px;
        width: 250px;
        display: block;
        position: absolute;
        background: url('~Assets/images/products/product-bg.png') center 25px no-repeat;
        // background: url('~Assets/images/products/blue-transparent-new.png') center 25px no-repeat;
        background-size: 200px;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
    }

    &.keg-wrapper {
        &::before {
            background: url('~Assets/images/products/blue-transparent-new.png') transparent center 25px no-repeat;
            content: url('~Assets/images/products/blue-transparent-new.png');
            opacity: 0.5;
        }
    }

    @include breakpoint(tablet-portrait-up) {
        &::before {
            content: url('~Assets/images/products/product-bg.png');
            position: absolute;
            bottom: -50px;
            background: transparent;
            height: auto;
            width: auto;
        }
        &.keg-wrapper {
            &::before {
                content: url('~Assets/images/products/blue-transparent-new.png');
                position: absolute;
                bottom: -50px;
                background: transparent;
                height: auto;
                width: auto;
                opacity: 0.5;
            }
        }
    }

    &::after {
        content: url('~Assets/images/products/smoke.png');
        position: absolute;
        bottom: -50px;
    }

    &.-no-image {
        padding-top: 125px;
    }

    .no-image {
        min-height: 200px;
    }

    .product-image {
        position: relative;
        max-height: 300px;
        width: auto;
        @include breakpoint(phone-small) {
            max-height: 200px;
        }
    }
}