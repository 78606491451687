@import 'sass/shared.scss';

.app-hero {
    &.-product {
        // height: 200px;
        // margin-top: 50px;
        height: 250px;
        padding-top: 60px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint(tablet-portrait-up) {
            height: 200px;
            min-height: 0;
            margin-top: 100px;
            padding-top: 0;
        }
    }

    > .hero-notice {
        box-shadow: 2px 2px 0px 1px rgba(0, 0, 0, 0.5);
        z-index: 1;
        display: block;
        text-align: center;
        width: 80%;
        max-width: 600px;
        padding: 10px;
        background: $brand-white;
        margin-top: 0px;

        @include breakpoint(tablet-portrait-up) {
            // margin-top: 60px;
            margin-top: 20px;
        }
    }

    border-bottom: 5px solid $brand-gray;
}

.single-product-cont {
    background: #ccc url('~Assets/images/backgrounds/noise.png') repeat;
    position: relative;
    padding: 40px 0;

    > .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('~Assets/images/backgrounds/single_product_desktop.png') no-repeat center;
        background-size: cover;
    }

    .centered-container {
        padding: 0 20px;
    }
}

.product-image-container.-single-product {
    text-align: center;

    > .product-img {
        max-height: 300px;
        width: auto;
        display: inline-block;

        @include breakpoint(tablet-landscape-up) {
            max-height: 400px;
        }
    }

    > .image-not-available {
        max-height: 500px;
        min-height: 150px;
        min-width: 100px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet-portrait-up) {
            min-height: 300px;
        }
    }
}

.product-desc-cont {
    text-align: center;
}

.product-options {
    margin: 20px 0;
    text-align: left;

    > .opt {
        margin-top: 20px;

        .label {
            max-width: 60px;
            width: 100%;
            text-align: right;
            display: inline-block;
            margin-right: 30px;
            @extend .roboto-bold, .h3;
        }

        .size {
            @extend .roboto-medium, .h5;
        }

        .counter-cont {
            display: inline-flex;
        }
    }
}

.product-img {
    @include breakpoint(phone-only) {
        width: 100%;
    }
}

.product-loader {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.cart-btn-single {
    width: 100%;

    @include breakpoint(tablet-portait-only) {
        width: auto;
    }
}
