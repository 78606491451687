@import 'sass/shared.scss';

.delete-account-content {
    background: $brand-white;
    padding: 90px $standard-spacing;

    @include breakpoint(tablet-portrait-up){
        padding: 150px 0;
    }
}


.delete-account-body {
    > .delete-account-heading {
        @extend .h2;
        @extend .roboto-medium;
        text-transform: uppercase;
        text-align: center;
    }

    > .delete-message {

        padding: $standard-spacing 0;
        @include breakpoint(tablet-portrait-up){
        padding: $standard-spacing * 3 0;
        }
    }

    > .delete-button-container {
        display: flex;
        justify-content: center;
    }
}
