@import 'sass/shared.scss';

.product-desc {
    text-align: center;
    > .product-title,
    > .product-type {
        @extend .tradesmith, .h2;
        color: $brand-gray;
        line-height: $h2;
        margin-bottom: 0;
    }

    > .product-price {
        @extend .roboto, .p;
        color: $brand-lightgray;
        line-height: $p;

        &.-bulk {
            @extend .roboto-medium, .h2;
            color: $brand-lightyellow;
            line-height: $h2;
            margin-top: 10px;
        }
    }
    > .line {
        width: 50px;
        height: 3px;
        background: $brand-lightgray;
        margin: 10px auto;
    }
    > .product-desc {
        @extend .roboto, .p;
        color: $brand-lightgray;
        max-width: 400px;
        margin: 0 auto;
    }
}
