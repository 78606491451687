@import 'sass/shared.scss';

.category-modal-container {
    max-width: auto;
    position: relative;
    overflow-wrap: break-word;
}

.question-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    
    .category-gate-question {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 7px 3px 7px;
        font-family: 'tradesmithrough';
        text-align: center;
        font-weight: bold;
        font-size: x-large;
        text-transform: uppercase;
        border-bottom: 2px solid #979797;
        width: 370px;
        border-right: 2px solid #979797;
        color: #525252;
        background-color: white;
        margin-top: -4px;
    }
    
}

.package-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 20px;
    max-width: auto;
    overflow: hidden;
    background: transparent;
    .container:last-child{
        // margin-top: 60px;
        .product-img-container {
            margin-bottom: 40px;
            height: 280px;
            &:before {
                width: auto;
                height: 354px;
                bottom: 0;
            }
            &:after {
                bottom: 0px;
            }
        }
    }
}

.modal {
    background: #ccc url('~Assets/images/backgrounds/noise.png') repeat;
}