@import 'sass/shared.scss';


.maintenance {

    >.hero-handle {
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -20px;
        width: 30px;
        height: auto;
        z-index: 1;

        @include breakpoint(tablet-landscape-up) {
            position: absolute;
            left: 20px;
            top: 50%;
            margin-top: -150px;
            width: auto;
            height: auto;
        }
    }
}

.maintenance-bg-container {
    background: url('~Assets/images/backgrounds/dots.png') 0 0 repeat;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    height: 100%;
    // padding: 110px 0 50px;
    width: 100%;

    @include breakpoint(tablet-portrait-up) {
        min-height: 100vh;
    }

    @include breakpoint(desktop-up) {
        height: 100%;
        min-height: 100vh;
    }

    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 50px;
        left: 0;
        top: 0;
        background: linear-gradient(to right, rgba(111, 180, 204, 0.62) 0%, rgba(125, 185, 232, 0) 100%);
    }
}

.maintenance-bg-image-container {
    background: url('~Assets/images/maintenance-magnets-mobile.png') no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    max-width: 100vw;

    @include breakpoint(desktop-up) {
        background: url('~Assets/images/maintenance-magnets-desktop.png') no-repeat center;
        background-size: cover;
        min-height: 100vh;
    }
}

.maintenance-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}


.maintenance-title {
    @extend .tradesmith,
    .h2;
    text-align: center;
    display: inline-block;
    width: auto;
    transform: scale(.7);


    & .h2 {
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
        background: radial-gradient(ellipse at center, rgba(244, 244, 244, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
        padding: 10px 20px;
        background: rgba(244, 244, 244, 1);
        text-shadow: 2px 2px 1px #6fb4cc;
        margin: 0 10px;

        @include breakpoint(tablet-portrait-up) {
            margin: 0 20px;

        }

    }


    @include breakpoint(tablet-portrait-up) {
        transform: scale(1);
    }
}

.maintenance-title-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;

    @include breakpoint(tablet-portrait-up) {
        margin-bottom: 25px;

    }

    &:first-of-type .h2:first-of-type {
        transform: rotate(-10deg);
    }

    &:first-of-type .h2:last-of-type {
        transform: rotate(5deg);
    }

    &:last-of-type .h2:first-of-type {
        transform: rotate(5deg);
    }

    // &:last-of-type .h2:last-of-type {
    //     transform: rotate(-10deg);
    // }
}


.maintenance-sub-title {
    color: $brand-red;
    text-align: center;
    margin-top: 5vh;
}
