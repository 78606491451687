@import 'sass/shared.scss';


.cart-go-to-case-form-container {
    > .existing-go-to-case-container {
        margin-bottom: $standard-spacing;
    }

    .cart-new-go-to-case {
        margin-bottom: $standard-spacing;
    }

    > .submit-actions {

    }
}

.existing-go-to-case-container {
    display: flex;

    > .go-to-case-selector {
        flex: 1;
    }
}

.cart-new-go-to-case {

}


.submit-actions.-cart-go-to-case {
    display: flex;
    justify-content: flex-end;
}
