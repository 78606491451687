
.lists.-unstyled {
    padding: 0;
    list-style: none;
}

.lists.-inline {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
    padding: 0;
}
