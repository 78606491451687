@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nikkji');
  src:  url('fonts/icomoon.eot?nikkji#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nikkji') format('truetype'),
    url('fonts/icomoon.woff?nikkji') format('woff'),
    url('fonts/icomoon.svg?nikkji#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-kegs:before {
  content: "\e915";
}
.icon-home:before {
  content: "\e916";
}
.icon-home-add:before {
  content: "\e917";
}
.icon-menu:before {
  content: "\e918";
}
.icon-work-add:before {
  content: "\e919";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-box:before {
  content: "\e901";
}
.icon-star:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-case:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-deals:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-google:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-location:before {
  content: "\e90b";
}
.icon-minus-box:before {
  content: "\e90c";
}
.icon-next:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-plus-box:before {
  content: "\e90f";
}
.icon-previous:before {
  content: "\e910";
}
.icon-trash:before {
  content: "\e911";
}
.icon-twitter:before {
  content: "\e912";
}
.icon-user:before {
  content: "\e913";
}
.icon-warning:before {
  content: "\e914";
}
