.cookie-prompt {
    background: rgba(27, 27, 27, .85);
    color: white;
    text-align: center;
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    vertical-align: middle;
    z-index: 10;
    // line-height: 50px;
    // font-size: 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 35px 30px;
}

p {
    flex-basis: initial;
    // padding-top: 10px;
    height: 100% auto;
    // margin: 0 auto;
    // font-size: 100%;
}

a {
    color: #f90;
    outline-style: none;
    text-decoration: none;
}

.cookie-button {
    // padding: 50px 10px !important;
    margin-left: 100px;
    // width: 200px;
}

.agree {
    // padding: 100px 10px !important;
    display: inline-flex;
}

.agree:hover {
    color: #f8a500;
}


// MEDIA QUERIES

// Extra extra small devices
@media (max-width: 429.98px) { 
    p {
        font-size: .4rem;
    }
 }

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 430px) and (max-width: 575.98px) { 
    p {
        font-size: .5rem;
    }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    p {
        font-size: .7rem;
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
    p {
        font-size: .9rem;
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) { 
    p {
        font-size: 1rem;
    }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    p {
        font-size: 1.2rem;
    }
 }