// COLORS
$brand-blue: #6fb4cc;
$brand-lightred: #df2824;
$brand-red: #c70317;
$brand-white: #ffffff;
$brand-gray: #575b5d;
$brand-lightgray: #707070;
$brand-yellow: #f8a500;
$brand-black: #000000;
$brand-lightyellow: #f4d637;

$brand-success: #00A72D;


// FONTS
$tradesmith: 'tradesmithrough';
$roboto: 'roboto';

// FONT SIZES
$h1: 36px;
$h2: 30px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$p: 16px;
$small: 14px;
$smallest: 12px;

$standard-spacing: 15px;

// Layout
$desktop-container: 1100px;

.h1 {
    @include font-size($h1);
}

.h2 {
    @include font-size($h2);
}

.h3 {
    @include font-size($h3);
}

.h4 {
    @include font-size($h4);
}

.h5 {
    @include font-size($h5);
}

.small {
    @include font-size($small);
}

.smallest {
    @include font-size($smallest);
}

.p {
    @include font-size($p);
}

.roboto {
    font-family: $roboto;
}

.roboto-medium {
    font-family: $roboto !important;
    font-weight: 600;
}

.roboto-italic {
    font-family: $roboto;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: $roboto;
    font-style: italic;
}

.roboto-bold {
    font-family: $roboto !important;
    font-weight: bold;
}

.roboto-bold-italic {
    font-family: $roboto;
    font-style: italic;
}

.tradesmith {
    font-family: $tradesmith;
}
