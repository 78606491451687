@import 'sass/shared.scss';



.cart-summary-col {
    display: none;

    &.-active {
        display: block;
    }

    @include breakpoint(tablet-landscape-up){
        display: block;
        border: 2px solid $brand-lightgray;
        position: sticky;
        top: 120px;
        left: 0;
    }

    > .cart-summary {
        background: url('~Assets/images/backgrounds/noise.png') 0 0 repeat;
    }
}


.cart-summary.-cart-summary-col {
    padding: $standard-spacing;

    @include breakpoint(tablet-landscape-up){
        padding-bottom: 30px;
    }

    > .heading {
        @extend .h3;
        @extend .roboto-bold;
        color: $brand-lightgray;
        text-align: center;
        padding: 20px 0;
        border-bottom: rgba($brand-lightgray, .5) 5px solid;
        display: none;


        @include breakpoint(tablet-landscape-up){
            display: block;
        }
    }
}


.summary-items-container {
    > .summary-items-group {
    }
}

.summary-items-group {
    > .summary-items-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    > .summary-items-list {

    }
}

.summary-items-heading {
    > .summary-heading-label {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-black;
    }

    > .summary-items-total {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-red;

    }
}

.summary-items-list {
    list-style: none;
    padding: 0;

    > .summary-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
}

.summary-item {
    > .item-name {
        @extend .roboto;
        @extend .p;
        color: $brand-black;
        padding-left: 10px;
        flex-basis: 60%;
    }

    > .item-cost {
        @extend .roboto;
        @extend .p;
        color: $brand-black;

    }
}


.cart-voucher-form {
    > .cart-discount-field {
        display: flex;
    }

    > .cart-discount-error-container {

    }
}

.cart-discount-field {

    > .discount-text-input {
        @extend .roboto;
        padding: 0 10px;
        height: 52px;
        border: 1px solid $brand-red;
        flex: 1;


    }

    > .discount-apply-trigger {
        @extend .tradesmith;
        height: 52px;
        background-color: $brand-red;
        color: $brand-white;
        border: 1px solid $brand-red;
        padding: 0 20px;
    }
}

.cart-discount-error-container {
    padding: 5px;

    > .message {
        @extend .roboto-italic;
        @extend .p;
        color: $brand-red;

    }
}




.cart-subtotal-container {

    &.-cart-summary {
        margin: 0;


        @include breakpoint(tablet-landscape-up){
            display: block;
        }
    }

}

.subtotal-row {

    > .label-text.-cart-summary {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-gray;
    }
    > .subtotal-figure.-cart-summary {
        @extend .h4;
        @extend .roboto-bold;
        color: $brand-red;
    }
}
