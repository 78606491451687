@import 'sass/shared';

.ant-modal.--snf-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    padding: 65px 20px;

    @include breakpoint(desktop-up) {
      padding-left: 55px;
      padding-right: 55px;
    }
  }

  @include breakpoint(desktop-up) {
    width: 100% !important;
    max-width: 780px !important;
  }
}

.snf-modal-body {
  > .modal-title {
    text-align: center;
  }
}

.modal-action-container {
  padding-top: 30px;

  > .ant-btn.modal-ok-trigger,
  .ant-btn.terms-trigger {
    display: block;
    width: 100%;

    @include breakpoint(desktop-up) {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.modal-result-message-container {
  text-align: center;

  > .result-type {
    text-align: center;
  }
}
