@import 'sass/shared.scss';

.counter-cont {
    display: flex;
    > .counter-btn {
        border: 0;
        padding: 0;
    }
    > input {
        @extend .roboto-medium, .p;
        max-width: 50px;
        text-align: center;
        border: 0px;
        -moz-appearance: textfield; /* Firefox */
    }
    > input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
}
.counter-btn {
    font-size: 28px;
    transition: all ease-in-out 0.2s;
    cursor: pointer;

    &:hover {
        color: $brand-black;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
            color: $brand-gray;
        }
    }

    > span {
        display: block;
    }
}
