@font-face {
    font-family: 'tradesmith';
    src: url('./tradesmithregular-webfont.woff2') format('woff2'), url('./tradesmithregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tradesmithrough';
    src: url('./tradesmithrough-webfont.woff2') format('woff2'), url('./tradesmithrough-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tradesmithround';
    src: url('./tradesmithround-webfont.woff2') format('woff2'), url('./tradesmithround-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tradesmithstamp';
    src: url('./tradesmithstamp-webfont.woff2') format('woff2'), url('./tradesmithstamp-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
