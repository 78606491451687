@import 'sass/shared.scss';

.mobile-navigation {
    position: fixed;
    top: 0;
    left: 0;

    bottom: 0;
    overflow-y: auto;
    background: rgba(255, 255, 255, 1);
    z-index: 9;
    display: none;
    padding-top: 60px;
    transition: all 0.3s ease-in-out;
    @include breakpoint(phone-only) {
        right: 0;
    }
    @include breakpoint(tablet-portrait-up) {
        padding-top: 110px;
        left: 0;
        width: 300px;
        left: inherit;
        bottom: inherit;
        transform: translateX(-300px);
    }

    &.-active {
        display: block;
        transform: translate(0px);
    }

    .menu-toggle-container {
        text-align: right;
        height: 40px;
        width: 100%;
        background: $brand-yellow;
        > .menu-close-toggle {
            padding: 10px;
        }
    }

    .menu-close-toggle {
        border: none;
        background: transparent;

        .icon-plus {
            color: $brand-white;
        }
    }
}

.menu-close-toggle {
    > .icon-plus {
        display: block;
        transform: rotate(45deg);
    }
}

.mobile-nav-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    > .mobile-nav-menu-item {
    }
}

.mobile-nav-menu-item {
    display: block;
    > .mobile-nav-menu-link {
    }
}

.mobile-nav-menu-link {
    @extend .p, .roboto-medium;

    display: block;
    text-align: center;
    padding: 10px;
    color: $brand-gray;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 5;
    display: none;
    opacity: 0;
    transition: all 1s ease-in-out;
    &.-active {
        display: block;
        opacity: 1;
    }
}
