.service-option {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.dropdown-option {
    position: absolute;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
}

.continue-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px 20px 0px;
}